import axios from 'axios'
import { useActiveChainId } from 'connection/useActiveChainId'
import { AIRDROP_API } from 'constants/misc'
import { atom, useAtom } from 'jotai'
import { useEffect } from 'react'

const rankingListAtom = atom<any>([])
const userPtsInfoAtom = atom<any>([])
export const epochAtom = atom<any>(2)
const rankLoadingAtom = atom<any>(false)
const roundGoldAtom = atom<any>(0)
const roundPointAtom = atom<any>(0)

export default function useRankingData() {
  const [rankingList, setRankingList] = useAtom(rankingListAtom)
  const [userPtsInfo, setUserPtsInfo] = useAtom(userPtsInfoAtom)
  const [epoch, setEpoch] = useAtom(epochAtom)
  const [isRankLoading, setIsRankLoading] = useAtom(rankLoadingAtom)
  const [roundGold, setRoundGold] = useAtom(roundGoldAtom)
  const [roundPoint, setRoundPoint] = useAtom(roundPointAtom)

  const { account } = useActiveChainId()

  useEffect(() => {
    if (epoch == 0) {
      setIsRankLoading(true)
      axios
        .get(`${AIRDROP_API}/dashboard/`)
        .then((res: any) => {
          // console.log('res rankingList', res.data)
          setIsRankLoading(false)
          setRankingList(res.data)
        })
        .catch((error: any) => {
          console.error('dashboard error', error)
        })
    } else {
      setIsRankLoading(true)
      axios
        .get(`${AIRDROP_API}/dashboard/round${epoch}`)
        .then((res: any) => {
          // console.log('res ,round', res.data)
          setIsRankLoading(false)
          setRankingList(res.data.users)
          setRoundPoint(res.data.total_blast_point)
          setRoundGold(res.data.total_blast_gold)
        })
        .catch((error: any) => {
          console.error('dashboard error', error)
        })
    }
  }, [epoch, setIsRankLoading, setRankingList, setRoundGold, setRoundPoint])

  useEffect(() => {
    if (!rankingList || !account || rankingList.length == 0) return
    const arr = rankingList.filter((item: any) => {
      return item.wallet_address.toLocaleLowerCase() == account.toLocaleLowerCase()
    })
    if (!arr || arr.length == 0) return
    // console.log('==', arr[0])
    setUserPtsInfo(arr[0])
  }, [rankingList, account, setUserPtsInfo])

  return {
    rankingList,
    userPtsInfo,
    epoch,
    isRankLoading,
    roundGold,
    roundPoint,
  }
}

export const goldPointAtom = atom<any>({})
export const totalUserAtom = atom<any>('')
export const totalPointAtom = atom<any>('')
export const roundListAtom = atom<any>([])

export function useRankingInfo() {
  const [goldPoint, setGoldPoint] = useAtom(goldPointAtom)
  const [totalUser, setTotalUser] = useAtom(totalUserAtom)
  const [totalPoint, setTotalPoint] = useAtom(totalPointAtom)
  const [roundList, setRoundList] = useAtom(roundListAtom)

  useEffect(() => {
    axios
      .get(`${AIRDROP_API}/dashboard/info`)
      .then((res) => {
        // console.log('res ,info', res.data)
        setGoldPoint(res.data.goldPoint)
        setTotalUser(res.data.total_user)
        setTotalPoint(res.data.total_airdrop_point)
        const arr0 = [{ round: 0, startTime: '', endTime: '' }]
        setRoundList([...arr0, ...res.data.roundList])
      })
      .catch((error) => {
        console.error('info error', error)
      })
  }, [setGoldPoint, setRoundList, setTotalPoint, setTotalUser])

  return { goldPoint, totalUser, totalPoint, roundList }
}
