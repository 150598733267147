import { Trans } from '@lingui/macro'
import { Box } from '@mui/material'
import Column from 'components/Column'
import { StyledDropDown } from 'components/Icons/StyledIcon'
import { NavDropdown } from 'components/NavBar/NavDropdown'
import Row from 'components/Row'
import dayjs from 'dayjs'
import { useOnClickOutside } from 'hooks/useOnClickOutside'
import { VeRoxSelect } from 'pages/Vote/HeaderWrapper'
import { useRef, useState } from 'react'
import { useAcitonsetCurrentId, useVoteCurrentId } from 'state/http/hooks'
import styled from 'styled-components/macro'
import { ThemedText } from 'theme'
import { DURATION, getAllEpochInfo, getEpochNum } from 'utils/CurrRound'

export const EpochList = styled(Column)`
  min-width: 300px;
  .epochHeader {
    padding: 6px 16px;
    border-bottom: 1px solid ${({ theme }) => theme.swapSectionBorder};
  }
  .epochItem {
    padding: 4px 16px;
    cursor: pointer;
    &:hover {
      background: ${({ theme }) => theme.buttonDisabledBackground};
    }
  }
`

export default function EpochDropdown() {
  const [isOpen, setIsOpen] = useState(false)
  const toggleOpen = () => {
    setIsOpen(!isOpen)
  }
  const epochNode = useRef<HTMLDivElement | null>(null)
  useOnClickOutside(epochNode, isOpen ? toggleOpen : undefined)

  const epochNum = useVoteCurrentId()
  const { setCurrentId } = useAcitonsetCurrentId()

  const epochInfo = getAllEpochInfo()

  return (
    <div style={{ position: 'relative' }} ref={epochNode}>
      <VeRoxSelect
        onClick={() => {
          toggleOpen()
        }}
      >
        <ThemedText.TextSecondary fontSize={14}>
          <Trans>Epoch {getEpochNum(epochNum)}</Trans>
        </ThemedText.TextSecondary>
        <StyledDropDown />
      </VeRoxSelect>
      {isOpen && (
        <NavDropdown right="0">
          <EpochList>
            <Row className="epochHeader">
              <Box width="80px">
                <ThemedText.TextSecondary fontWeight={700} fontSize={12}>
                  Epoch
                </ThemedText.TextSecondary>
              </Box>
              <ThemedText.TextSecondary fontWeight={700} fontSize={12}>
                Date
              </ThemedText.TextSecondary>
            </Row>
            <Column gap="6px" width="100%" style={{ paddingTop: '8px' }}>
              {epochInfo.length > 0 &&
                epochInfo.map((item, index) => {
                  return (
                    <Row
                      className="epochItem"
                      onClick={() => {
                        setCurrentId(item.startTime / DURATION), toggleOpen()
                      }}
                      key={index}
                    >
                      <Box width="80px">
                        <ThemedText.TextPrimary>{item.epochNum}</ThemedText.TextPrimary>
                      </Box>
                      <ThemedText.TextSecondary fontSize={14}>
                        {dayjs(item.startTime * 1000).format('YYYY/MM/DD')}-
                        {item.endTime?dayjs(item.endTime * 1000).format('YYYY/MM/DD'):" "}
                      </ThemedText.TextSecondary>
                    </Row>
                  )
                })}
            </Column>
          </EpochList>
        </NavDropdown>
      )}
    </div>
  )
}
