import { Trans } from '@lingui/macro'
import { Box } from '@mui/material'
import { ButtonConfirmed } from 'components/Button'
import Column from 'components/Column'
import SwapCurrencyInputPanel from 'components/CurrencyInputPanel/SwapCurrencyInputPanel'
import { useDynamicApprove } from 'components/DynamicApprove'
import Row, { RowBetween } from 'components/Row'
import {
  ConfirmationModalContent2222,
  TransactionConfirmationModal22222,
} from 'components/TransactionConfirmationModal'
import { useRoguxCurrency } from 'constants/tokens'
import dayjs from 'dayjs'
import { useCurrency } from 'hooks/Tokens'
import { useLockContract } from 'hooks/useContract'
import { useInputAmount } from 'hooks/useInputAmount'
import { useCallback, useMemo, useState } from 'react'
import { useTransactionAdder } from 'state/transactions/hooks'
import { TransactionType } from 'state/transactions/types'
import styled from 'styled-components/macro'
import { ThemedText } from 'theme'
import { BN } from 'utils/bn'
import { DURATION } from 'utils/CurrRound'
import { handlerError } from 'utils/formatError'

import LockPicker from './LockPicker'
import TimeCard from './TimeCard'

export const StyledLockCard = styled(Row)`
  border: 1px solid ${({ theme }) => theme.swapSectionBorder};
  padding: 16px;
  border-radius: 16px;
  margin-top: 8px;
  flex-direction: column;

  .infoItem {
    padding: 6px 0;
    &:not(:last-child) {
      border-bottom: 1px solid ${({ theme }) => theme.swapSectionBorder};
    }
  }
`

export const AmountBox = styled(Box)`
  display: flex;
  flex-direction: column;
  grid-gap: 8px;
  width: 100%;
  margin-top: 8px;
`

export const BoxCard = styled(Box)<{ isDisabled?: boolean }>`
  background-color: ${({ theme, isDisabled }) => (isDisabled ? theme.backgroundInteractive : 'transparent')};
  border: 1px solid ${({ theme }) => theme.swapSectionBorder};
  padding: 16px 8px 8px;
  border-radius: 16px;
`

interface CreateModalProps {
  isOpen: boolean
  onDismiss: () => void
}
export default function CreateLockModal({ isOpen, onDismiss }: CreateModalProps) {
  const [attemptingTxn, setAttemptingTxn] = useState<boolean>(false)
  const [txHash, setTxHash] = useState<string>('')
  const [txError, setTxError] = useState<string>('')
  const pendingText = 'pending ...'

  const handleDismissConfirmation = () => {
    onDismiss && onDismiss()
    setTimeout(() => {
      setAttemptingTxn(false)
      setTxHash('')
      setTxError('')
    }, 300)
  }
  const leftTime = () => {
    const currTime = Math.round(Number(new Date()) / 1000)
    const roundTime = Math.floor(currTime / DURATION) * DURATION + DURATION
    return 7 - Math.round((roundTime - currTime) / 86400)
  }
  const [weeks, setWeeks] = useState(1)
  const unLockDate = useMemo(() => {
    return dayjs()
      .add(weeks * 7 - leftTime(), 'day')
      .format('YYYY-MM-DD')
  }, [weeks])

  const RoguxCurrency = useRoguxCurrency()
  const tokenCurrency = useCurrency(RoguxCurrency?.address)

  const { inputAmount: amount, handleUserInput: setAmount, isInputValid, inputError } = useInputAmount(RoguxCurrency)
  const handleAmountChange = useCallback(
    (amount: string) => {
      setAmount(amount)
    },
    [setAmount]
  )

  const LockContract = useLockContract()
  const { DynamicApprove, isApproved } = useDynamicApprove([amount.amount], LockContract?.address)

  const addTransaction = useTransactionAdder()
  const handleLock = useCallback(async () => {
    if (!weeks || !amount.amount?.numerator.toString() || !LockContract) return

    setAttemptingTxn(true)
    try {
      const response = await LockContract.create_lock(amount.amount.numerator.toString(), DURATION * weeks)
      setAttemptingTxn(false)
      addTransaction(response, {
        type: TransactionType.CREATE_LOCK,
        token0Address: '',
        token1Address: '',
      })
      setTxHash(response.hash)
    } catch (error) {
      setAttemptingTxn(false)
      setTxError(handlerError(error))
      console.log('create Lock error', error)
    }
  }, [LockContract, addTransaction, amount, weeks])

  const VotingPower = useMemo(() => {
    const VotingPoweramount = amount.value
    if (!VotingPoweramount) return 0
    const unix = BN(weeks * 7 - leftTime()).times(86400)
    // const min = BN(1).minus(BN(3).div(4))
    const min = BN(1)
    const max = BN(365).times(86400)
    //  数量 * (1 - 3/4) / (365*86400) * 质押多少秒
    const predict = BN(VotingPoweramount || 0)
      .times(min)
      .div(max)
      .times(unix)
    return predict.toFixed(2)
  }, [amount.value, weeks])

  return (
    <TransactionConfirmationModal22222
      isOpen={isOpen}
      onDismiss={handleDismissConfirmation}
      attemptingTxn={attemptingTxn}
      hash={txHash}
      txError={txError}
      width="400px"
      pendingText={pendingText}
      reviewContent={
        <ConfirmationModalContent2222
          title={<Trans>Create New Lock</Trans>}
          onDismiss={handleDismissConfirmation}
          topContent={
            <Column gap="sm">
              <AmountBox>
                <ThemedText.HeadNav054>
                  <Trans>Amounts</Trans>
                </ThemedText.HeadNav054>
                <BoxCard>
                  <Box p=" 0 8px" pb="12px">
                    <SwapCurrencyInputPanel
                      label={<Trans>From</Trans>}
                      value={amount.value}
                      showMaxButton={false}
                      currency={tokenCurrency}
                      id="LockAmount"
                      onUserInput={handleAmountChange}
                      // onMax={handleMaxInput}
                      // fiatValue={{ data: Number(amount), isLoading: false }}
                      // onCurrencySelect={handleInputSelect}
                      // otherCurrency={currencies[Field.OUTPUT]}
                      loading={false}
                      onMaxTab={handleAmountChange}
                      showMaxTab={true}
                    />
                  </Box>
                </BoxCard>
              </AmountBox>
              <LockPicker title="Lock Until" />
              <TimeCard setWeeks={setWeeks} />
              <StyledLockCard>
                <RowBetween gap="sm" className="infoItem">
                  <ThemedText.TextSecondary fontSize={14}>
                    <Trans>Voting Power</Trans>
                  </ThemedText.TextSecondary>
                  <ThemedText.TextPrimary fontWeight={700}>
                    <Trans>{VotingPower} veROX</Trans>
                  </ThemedText.TextPrimary>
                </RowBetween>
                <RowBetween gap="sm" className="infoItem">
                  <ThemedText.TextSecondary fontSize={14}>
                    <Trans>Expire in</Trans>
                  </ThemedText.TextSecondary>
                  <ThemedText.TextPrimary>
                    <Trans>{weeks * 7 - leftTime()} Days</Trans>
                  </ThemedText.TextPrimary>
                </RowBetween>
                <RowBetween gap="sm" className="infoItem">
                  <ThemedText.TextSecondary fontSize={14}>
                    <Trans>Lock Until</Trans>
                  </ThemedText.TextSecondary>
                  <ThemedText.TextPrimary>
                    <Trans>{unLockDate}</Trans>
                  </ThemedText.TextPrimary>
                </RowBetween>
              </StyledLockCard>
              <Column width="100%" mt="8px" gap="sm">
                <DynamicApprove />
                <ButtonConfirmed disabled={!isInputValid || !isApproved} onClick={handleLock}>
                  {isInputValid ? 'Lock' : inputError}
                </ButtonConfirmed>
              </Column>
            </Column>
          }
        />
      }
    />
  )
}
